<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-8 py-8">
      <template v-if="dataUser !== null">
        <v-row>
          <v-col
            class="px-8 py-8"
          >
            <v-badge
              bottom
              offset-x="25"
              offset-y="25"
              avatar
              color="white"
            >
              <v-avatar
                size="100"
              >
                <v-img
                  :src="dataUser.p_avatar"
                  alt="profileimage"
                />
              </v-avatar>
              <template
                v-slot:badge
              >
                <v-icon
                  color="green"
                  size="22"
                  @click="changeAvatar"
                >
                  mdi-pencil-circle
                </v-icon>
              </template>
            </v-badge>
          </v-col>
        </v-row>

        <v-row class="mt-10 mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Nama Lengkap
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_name }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Posisi
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_pos_divisi }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Peran
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_pos_name }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr>
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Telepon
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_phone }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Email Perusahaan
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_email }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Email Pribadi
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_email_personal }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr>
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col
            class="font-common"
            cols="3"
          >
            Alamat
          </v-col>
          <v-col
            class="font-common-bold"
            cols="9"
          >
            {{ dataUser.p_address_string }}
          </v-col>
        </v-row>

        <v-row class="mb-n2">
          <v-col>
            <v-btn
              class="elevation-0 mt-4"
              block
              style="
              background: #FFC709;
              border: none;
              outline: none;
            "
              @click="dialogUbahPassword = true"
            >
              <span class="font-common-white-bold text-capitalize">
                Ubah Kata Sandi
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>

    <!-- DIALOG UPLOAD FOTO -->
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-container>
          <v-row class="px-2">
            <v-col>
              <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                class="d-flex justify-center"
                style="width: 100%;"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                @vdropzone-file-added="vdropzoneFileAdded"
              >
                <div class="dropzone-custom-content">
                  <v-btn
                    class="mb-2"
                    color="purple"
                  >
                    UPLOAD FOTO
                  </v-btn>
                  <h4 class="dropzone-custom-title">
                    Atau tarik foto ke area ini untuk upload
                  </h4>
                </div>
              </vue-dropzone>
            </v-col>
          </v-row>

          <!-- <v-card-text /> -->
          <v-card-actions>
            <v-btn
              class="mb-2"
              color="purple"
              block
              @click="saveButtonClick"
            >
              SIMPAN FOTO
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOG UBAH PASSWORD -->
    <v-dialog
      v-model="dialogUbahPassword"
      max-width="600"
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col>
            <span class="font-common-black-bold">
              Ubah Kata Sandi
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              class="font-common-bold"
              style="cursor: pointer"
              @click="dialogUbahPassword = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row
          class="pt-8 mb-n5"
        >
          <v-col
            class="font-common"
            cols="4"
          >
            Kata Sandi Lama
          </v-col>

          <v-col
            cols="8"
          >
            <v-text-field
              v-model="password.oldPassword"
              placeholder="Masukkan Kata Sandi Lama"
              color="purple"
              type="password"
              required
              outlined
              dense
              :error-messages="errOldPassword"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            class="font-common"
            cols="4"
          >
            Kata Sandi Baru
          </v-col>

          <v-col
            cols="8"
          >
            <v-text-field
              v-model="password.newPassword"
              placeholder="Masukkan Kata Sandi Baru"
              color="purple"
              type="password"
              required
              outlined
              dense
              :error-messages="errNewPassword"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            class="font-common"
            cols="4"
          >
            Konfirmasi Kata Sandi
          </v-col>

          <v-col
            cols="8"
          >
            <v-text-field
              v-model="password.confirmPassword"
              placeholder="Masukkan Kata Sandi Baru"
              color="purple"
              type="password"
              required
              outlined
              dense
              :error-messages="errConfirmPassword"
            />
          </v-col>
        </v-row>

        <v-alert
          v-model="showAlert"
          type="error"
          dismissible
          dense
        >
          {{ textAlert }}
        </v-alert>

        <v-row class="mt-4">
          <v-col
            class="d-flex"
            style="justify-content: flex-end"
          >
            <v-btn
              class="text-capitalize font-common-bold"
              color="purple"
              @click="ubahPassword"
            >
              Simpan
            </v-btn>

            <v-btn
              class="text-capitalize font-common-bold"
              style="
                background: #F2F2F2;
                border: none;
                outline: none;
              "
              @click="dialogUbahPassword = false"
            >
              <span style="color: #CCCCCC">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      vueDropzone: vue2Dropzone,
    },

    data: () => ({
      dialog: false,
      dialogUbahPassword: true,
      dataUser: null,
      profilePicture: {},
      profilePictureUploadedHash: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        autoQueue: false,
        autoProcessQueue: false,
        maxFiles: 1,
      },

      password: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      errOldPassword: '',
      errNewPassword: '',
      errConfirmPassword: '',

      showAlert: false,
      textAlert: '',
    }),

    computed: {
      // passwordConfirmationRule () {
      //   return this.password.newPassword === this.password.confirmPassword || 'Password must match'
      // },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('xxx')) {
        //   this.xxx = true
        // }

        const vm = this

        axios.post('/v1/t/profile/personal').then(function (response) {
          if (response.data.status === 200) {
            vm.dataUser = response.data.data.profile
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      saveButtonClick () {
        const requestBody = {
          hash_avatar: JSON.stringify(this.profilePictureUploadedHash),
        }

        axios.post('/v1/t/profile/personal/changeAvatar', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })

        this.dialog = false
      },

      vdropzoneFileAdded (file) {
        const dropzoneFiles = this.$refs.myVueDropzone.dropzone.files
        if (dropzoneFiles.length > 1) {
          document.querySelector('.dz-preview').remove()
          dropzoneFiles.shift()
        }

        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', file)

        axios.post(baseCDNurl + '/v1/t/profile/personal/uploadAvatar', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            document.querySelectorAll('.dz-details').forEach(el => el.remove())
            document.querySelectorAll('.dz-progress').forEach(el => el.remove())
            this.profilePictureUploadedHash.push(res.data.data.tmp_file_hash)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      changeAvatar () {
        this.profilePicture = {}
        this.profilePictureUploadedHash = []
        this.dialog = true
        this.$nextTick(() => {
          this.$refs.myVueDropzone.removeAllFiles()
        })
      },

      ubahPassword () {
        if (!this.fieldValidation()) return

        const requestBody = {
          old_pass: this.password.oldPassword,
          new_pass: this.password.newPassword,
          new_pass_verif: this.password.confirmPassword,
        }

        axios.post('/v1/t/profile/personal/changePwd', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.password.oldPassword = ''
            this.password.newPassword = ''
            this.password.confirmPassword = ''
            this.dialogUbahPassword = false
            this.$toast.success(res.data.message)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.textAlert = Object.values(e.response.data.error)[0]
            } else {
              this.textAlert = e.response.data.error
            }
            this.showAlert = true
          })
      },

      fieldValidation () {
        let errCount = 0

        if (this.password.oldPassword.trim().length === 0) {
          this.errOldPassword = 'Password lama harus diisi'
          errCount++
        } else {
          this.errOldPassword = ''
        }

        if (this.password.newPassword.trim().length === 0) {
          this.errNewPassword = 'Password baru harus diisi'
          errCount++
        } else {
          this.errNewPassword = ''
        }

        if (this.password.confirmPassword.trim().length === 0) {
          this.errConfirmPassword = 'Konfirmasi password harus diisi'
          errCount++
        } else if (this.password.newPassword !== this.password.confirmPassword) {
          this.errConfirmPassword = 'Konfirmasi password yang anda masukan tidak sama'
          errCount++
        } else {
          this.errConfirmPassword = ''
        }

        if (errCount > 0) return false

        return true
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}
</style>
